import { Russian } from "flatpickr/dist/l10n/ru.js"
import './index.css';
export default {
  name: 'input-date',
  props: {
    value: {
      type: [String, Date, Object, Number],
      default: () => ''
    },
    name: {
      type: String,
      default: 'date'
    },
    label: {
      type: String,
      default: 'Дата'
    },
    placeholder: {
      type: String,
      default: 'Введите дату отправления в формате ДД/ММ/ГГГГ'
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      inputValue: ''
    }
  },
  created() {
    if (this.inputValue != this.$props.value)
      this.inputValue = this.$props.value;

    this.$nextTick(() => {
      if (!this.$props.disabled) {
        this.flatpickr(this.$el.querySelector('.date-input'), {
          "locale": Russian,
          enableTime: false,
          dateFormat: "Y-m-d",
        });
      }
    });
  },
  watch: {
    'inputValue'(newVal) {
      this.$emit('input', newVal);
      this.$emit('change', newVal);
    },
    '$props.value'(newVal) {
      if (this.inputValue != newVal)
        this.inputValue = newVal;
    }
  },
  computed: {},
  methods: {
    onChange(e) {
      this.inputValue = e.target.value;
    }
  }
}
