import Vue from 'vue';
import Vuex from 'vuex';
import {stores as ModuleStores} from './views';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    endpoint: process.env.VUE_APP_ENDPOINT,
    snackbar: {
      show: false,
      duration: 4000,
      message: ''
    },
  },
  mutations: {
    showSnackbar(state, data) {
      let timeOut = (data[1]) ? data[1] : 4000;
      state.snackbar.duration = timeOut;
      state.snackbar.message  = data[0];
      state.snackbar.show     = true;
      setTimeout(function () {
        state.snackbar.show = false;
        state.snackbar.message = '';
      }, timeOut);
    },
    hideSnackbar(state) {
      state.snackbar.show    = false;
      state.snackbar.message = '';
    }
  },
  actions: {},
  modules: ModuleStores
});
