import Vue from 'vue';
import './index.css';
export default {
  name: 'input-address',
  props: {
    value: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: true,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      inputValue: '',
      focus: false,
      loading: false,
      suggestions: []
    }
  },
  created() {
    this.$nextTick(() => {
      this.inputValue = this.$props.value;
      this.listeners();
    });
  },
  watch: {
    'inputValue'(newVal) {
      this.$emit('input', newVal);
      this.$emit('change', newVal);
      if (newVal.length > 3 && this.focus && !this.loading) this.suggest();
      else this.suggestions = [];
    },
    '$props.value'(newVal) {
      if (this.inputValue != newVal)
        this.inputValue = newVal;
    }
  },
  computed: {
    id() {
      return this._uid;
    }
  },
  methods: {
    listeners() {
      document.addEventListener('click', e => {
          if (this.$refs && this.$refs.component && !this.$refs.component.contains(e.target)) this.onBlur();
      });
    },
    onSelect(suggest) {
      this.inputValue = suggest.unrestricted_value;
      this.$emit('suggest', suggest);
    },
    suggest() {
      this.loading = true;
      return Vue.http.get(this.$root.endpoint + '/suggest?count=20&type=address&query=' + this.inputValue).then(
        response => {
          this.suggestions = Object.assign([], response.body.response);
        },
        response => this.commit('showSnackbar', [response.body.errors.messages, 6000])
      ).finally(() => this.loading = false);
    },
    onFocus() {
      this.focus = true;
    },
    onBlur() {
      this.focus = false;
    },
    onChange(e) {
      this.inputValue = e.target.value;
    },
    onInput(e) {
      this.inputValue = e.target.value;
    }
  }
}
