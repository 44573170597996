import inputDate from './../../../../components/input-date/index.vue';
import inputSelect from './../../../../components/input-select/index.vue';
import inputText from './../../../../components/input-text/index.vue';
import inputCompany from './../../../../components/input-company/index.vue';
import inputRadio from './../../../../components/input-radio/index.vue';
import inputAddress from './../../../../components/input-address/index.vue';
import inputMultiCheckbox from './../../../../components/input-multi-checkbox/index.vue';
import inputCheckbox from './../../../../components/input-checkbox/index.vue';
import inputButton from './../../../../components/input-button/index.vue';
import inputDeal from './../../../../components/input-deal/index.vue';
import Vue from 'vue';
export default {
    name: 'home',
    components: {
      inputDeal,
      inputButton,
      inputDate,
      inputSelect,
      inputText,
      inputRadio,
      inputCompany,
      inputAddress,
      inputCheckbox,
      inputMultiCheckbox
    },
    data () {
        return {
          findForm: false,
          deliveryMethodsFrom: [
            {id: 2, name: 'Забор с адреса клиента'},
            {id: 1, name: 'Отправка с терминала компании'}
          ],
          customerTypes: [
            {label: 'Юридическое лицо', value: 1},
            {label: 'Индивидуальный предприниматель', value: 3},
            {label: 'Физическое лицо', value: 2},
          ],
          payerTypes: [
            {label: 'Отправитель', value: 1},
            {label: 'Получатель', value: 2},
            {label: 'Третье лицо', value: 3},
          ],
          applicantTypes: [
            {label: 'Отправитель', value: 1},
            {label: 'Получатель', value: 2},
            {label: 'Заявитель', value: 3},
          ],
          freightServices: [],
          stocks: [],
          stocksSuggest: [],
          search: '',
          form: {
            deal: {
              agree: false,

              delivery_method_from: 2,
              delivery_method_to:   2,
              payer_type: 1,
              applicant_type: 1,


              executor_company_id:  null,
              description:          null,
              from_stock_id:        null,
              to_stock_id:          null,
              from_address_id:      null,
              from_address:         {
                address: ''
              },
              to_address_id:        null,
              to_address:           {
                address: ''
              },
              applicant_id:         null,
              sender_id:            null,
              recipient_id:         null,
              payer_id:             null,
              status_id:            null,
              traking_number:       null,
              forwarding_date_from: null,
              forwarding_date_to:   null,
              responsible_id:       null,
              forwarder_from_id:    null,
              forwarder_to_id:      null,
              to_signature:         null,
              from_signature:       null,
              to_signature_date:    null,
              from_signature_date:  null,


              sender: {
                search: '',
                name: '',
                type: 1,
                inn: '',
                kpp: '',
                passport_number: null,
                checking_account: '',
                correspondent_account: '',
                bic: '',
                bank: '',
                sort: 100,
                phone: '',
                email: "",
                contacts: []
              },

              recipient: {
                search: '',
                name: '',
                type: 1,
                inn: '',
                kpp: '',
                passport_number: null,
                checking_account: '',
                correspondent_account: '',
                bic: '',
                bank: '',
                sort: 100,
                phone: '',
                email: "",
                contacts: []
              },

              payer: {
                search: '',
                name: '',
                type: 1,
                inn: '',
                kpp: '',
                passport_number: null,
                checking_account: '',
                correspondent_account: '',
                bic: '',
                bank: '',
                sort: 100,
                phone: '',
                email: "",
                contacts: []
              },

              sender_contact: {
                id: '',
                name: '',
                phone: '',
                email: '',
              },

              recipient_contact: {
                id: '',
                name: '',
                phone: '',
                email: ''
              },

              payer_contact: {
                id: '',
                name: '',
                phone: '',
                email: ''
              }

            },
            freight: {
              id: '',
              description: '',
              sort: 100,
              size: '',
              size_units: 'м',
              status_id: '',
              traking_number: '',
              deal_id: '',
              departure_date: '',
              weight: '',
              seats_number: '',
              doom: '',
              factory_package: '',
              price: '',
              tracking_id: '',
              forwarder_id: null,
              forwarding_date: '',
              forward_type: null,
              from_signature: '',
              to_signature: ''
            },
            service: []
          }
        }
    },
    computed: {},
    watch: {
      'stocks'(stocks) {
        if (stocks && stocks.length) this.stocksSuggest = stocks.map(stock => {
          return {
            id: stock.id,
            name: stock.name + ', ' + stock.city.name
          }
        })
      }
    },
    methods: {
      submit() {
        this.$validator.validateAll().then(valid => {
          if (!valid) return;
          this.form.service = Object.assign([], this.freightServices);
          Vue.http.post(this.$root.endpoint + '/ext/deal', this.form).then(
            response => {
              return response;
            },
            response => {
              return response;
            }
          )
        });
      },

      onFind(data) {
        this.form.deal    = Object.assign({}, data.deal);
        this.form.freight = Object.assign({}, data.freight);
        this.form.service = Object.assign({}, data.service);

        //this.$set(this.form, 'deal', data.deal);
        //this.$set(this.form, 'freight', data.freight);
        //this.$set(this.form, 'service', data.service);
      },

      /**
       * Получение списка складов, доступных в CRM системе
       * @returns {*|PromiseLike<T>|Promise<T>}
       */
      getStocks() {
        return Vue.http.get(this.$root.endpoint + '/stock?count=50').then(
          response => {
            this.stocks = Object.assign([], response.body.response.data);
            return response;
          }
        )
      },

      /**
       * Выбор контакта отправителя из списка существующих у указанного контрагента
       * @param contact
       */
      onSelectSenderContact(contact) {
        this.form.deal.sender_contact = Object.assign({}, contact);
      },

      /**
       * Выбор контакта получателя из списка существующих у указанного контрагента
       * @param contact
       */
      onSelectRecipientContact(contact) {
        this.form.deal.recipient_contact = Object.assign({}, contact);
      },

      /**
       * Выбор контакта платльщика из списка существующих у указанного контрагента
       * @param contact
       */
      onSelectPayerContact(contact) {
        this.form.deal.payer_contact = Object.assign({}, contact);
      },

      /**
       * Выбор отправителя из списка предложенных ДаДата/из БД
       * @param sender
       */
      suggestSender(sender) {
        if (sender.id) {
          this.form.deal.sender = Object.assign({}, sender);
          if (sender.addresses && sender.addresses.length) {
            let address = sender.addresses.find(item => !!item);
            this.form.deal.from_address_id = address.id;
            this.form.deal.from_address    = Object.assign({}, address);
          }
          if (sender.contacts && sender.contacts.length) {
            let contact = sender.contacts.find(item => !!item);
            this.onSelectSenderContact(contact);
          }
        } else {
          this.form.deal.sender.name = sender.name;
          this.form.deal.sender.kpp  = sender.kpp;
          this.form.deal.sender.inn  = sender.inn;
          this.form.deal.sender.type = sender.type;
        }
      },

      /**
       * Выбор получателя из списка предложенных ДаДата/из БД
       * @param recipient
       */
      suggestRecipient(recipient) {
        if (recipient.id) {
          this.form.deal.recipient = Object.assign({}, recipient);
          if (recipient.addresses && recipient.addresses.length) {
            let address = recipient.addresses.find(item => !!item);
            this.form.deal.to_address_id = address.id;
            this.form.deal.to_address    = Object.assign({}, address);
          }
          if (recipient.contacts && recipient.contacts.length) {
            let contact = recipient.contacts.find(item => !!item);
            this.onSelectRecipientContact(contact);
          }
        } else {
          this.form.deal.recipient.name = recipient.name;
          this.form.deal.recipient.kpp  = recipient.kpp;
          this.form.deal.recipient.inn  = recipient.inn;
          this.form.deal.recipient.type = recipient.type;
        }
      },

      /**
       * Выбор плательщика из списка предложенных ДаДата/из БД
       * @param payer
       */
      suggestPayer(payer) {
        if (payer.id) {
          this.form.deal.payer = Object.assign({}, payer);
          if (payer.contacts && payer.contacts.length) {
            let contact = payer.contacts.find(item => !!item);
            this.onSelectPayerContact(contact);
          }
        } else {
          this.form.deal.payer.name = payer.name;
          this.form.deal.payer.kpp  = payer.kpp;
          this.form.deal.payer.inn  = payer.inn;
          this.form.deal.payer.type = payer.type;
          this.form.deal.payer_type = payer.type;
        }
      },

      /**
       * Получает с сервера список активных дополительных услуг перевозки
       * @param section
       * @returns {*|PromiseLike<T>|Promise<T>}
       */
      getFreightServices(section) {
        let params = {
          orderBy: 'sort',
          filter: (section) ?
            {model: [['section','=',section],'and',['active','=','1']], relation: []} :
            {model: [['active','=','1']], relation: []}
        };
        return Vue.http.get(this.$root.endpoint + '/deals/services', {params: params}).then(response => {
          let services = response.body.response.map(item => {
            if (item.type === 'checkbox') item.value = [];
            return item;
          });
          this.freightServices = Object.assign([], services);
          return services;
        })
      }
    },
    created:  function() {
      this.$nextTick(() => {
        this.getStocks();
        this.getFreightServices();
      })
    }
}
