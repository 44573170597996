import WacomSignatureManager from './../../assets/js/signature/WacomSignatureManager';
import './index.css';
export default {
  name: 'WacomSignature',
  props: {
    value: {
      type: String,
      default: ''
    },
    imgWidth: {
      type: Number,
      default: 200
    },
    imgHeight: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      signature: null,
      sigVal: '',
      canSignature: false
    }
  },
  created() {
    this.$nextTick(() => {
        try {
            this.signature = new WacomSignatureManager({width: this.$props.imgWidth, height: this.$props.imgHeight});
            this.signature.OnLoad((e) => console.log('capture is loaded', e), (e) => {
              console.log('capture is canceled', e)
              this.$emit('capture-cancel');
            });
            if (this.$props.imgWidth) this.$refs.img.style.width = this.$props.imgWidth + 'px';
            if (this.$props.imgHeight) this.$refs.img.style.height = this.$props.imgHeight + 'px';
            this.$on('capture', this.capture);

            this.canSignature = true;
        } catch (e) {
         alert('Планшет для подписи не подключен');
         this.canSignature = false;
        }
        if (this.$props.value !== this.sigVal) this.sigVal = this.$props.value;
    });
  },
  watch: {
    '$props.value'(val) {
      if (val !== this.sigVal) this.sigVal = val;
    },
    'sigVal'(val) {
      if (this.$props.value !== val) {
        this.$emit('input', val);
        this.$emit('change', val);
      }
    }
  },
  computed: {},
  methods: {
    capture() {
      this.signature.Capture(this.onCapture);
    },
    onCapture(sig) {
      console.log('capture is completed', sig);
      this.sigVal = sig;
      this.$emit('capture-complete', sig);
    }
  }
}