import './index.css';
export default {
    name: 'input-payment-method',
    props: {
        value: {
            type: Number,
            default: null
        },
        methods: {
            type: Array,
            return: () => []
        },
        label: {
            type: String,
            default: null
        },
    },
    components: {},
    data() {
        return {
            inputValue: '',
            acceptedTypes: [2, 3],
        }
    },
    created() {
        this.$nextTick(() => {
            this.inputValue = this.$props.value;
        });
    },
    watch: {
        'inputValue'(newVal) {
            this.$emit('input', newVal);
            this.$emit('change', newVal);
        },
        '$props.value'(newVal) {
            if (this.inputValue != newVal) this.inputValue = newVal;
        }
    },
    computed: {
        items() {
            return this.$props.methods.filter(item => this.acceptedTypes.indexOf(parseInt(item.type))+1);
        }
    },
    methods: {
        onSelect(value) {
            this.inputValue = parseInt(value);
        },
        onChange(e) {
            this.inputValue = e.target.value;
        },
        onInput(e) {
            this.inputValue = e.target.value;
        }
    }
}
  