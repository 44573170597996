import inputPaymentMethod from './../input-payment-method/index.vue';
import inputDate from './../input-date/index.vue';
import inputSelect from './../input-select/index.vue';
import inputText from './../input-text/index.vue';
import inputCompany from './../input-company/index.vue';
import inputCustomer from './../input-customer/index.vue';
import inputRadio from './../input-radio/index.vue';
import inputAddress from './../input-address/index.vue';
import inputMultiCheckbox from './../input-multi-checkbox/index.vue';
import inputCheckbox from './../input-checkbox/index.vue';
import inputButton from './../input-button/index.vue';
import inputDeal from './../input-deal/index.vue';
import WacomSignature from './../wacom-signature/index.vue';
import Vue from 'vue';
import './index.css';
export default {
  name: 'request-form',
  components: {
    inputDeal,
    inputButton,
    inputDate,
    inputSelect,
    inputText,
    inputRadio,
    inputCompany,
    inputCustomer,
    inputAddress,
    inputCheckbox,
    inputMultiCheckbox,
    WacomSignature,
    inputPaymentMethod
  },
  data () {
    return {
      findForm: true,
      loading: false,
      complete: false,
      error: '',
      backTime: 30,
      backTimeFn: null,
      ttn: true,
      paymentMethods: [],
      savedDeal: {
        id: null
      },
      deliveryMethodsFrom: [
        {id: 2, name: 'Забор с адреса клиента'},
        {id: 1, name: 'Отправка с терминала компании'}
      ],
      deliveryMethodsTo: [
        {id: 2, name: 'Доставка до адреса клиента'},
        {id: 1, name: 'Доставка до терминала компании'}
      ],
      vatValues: [
        {id: 1, name: 'С НДС'},
        {id: 2, name: 'Без НДС'}
      ],
      customerTypes: [
        {label: 'Юридическое лицо', value: 1},
        {label: 'Индивидуальный предприниматель', value: 3},
        {label: 'Физическое лицо', value: 2},
      ],
      payerTypes: [
        {label: 'Отправитель', value: 1},
        {label: 'Получатель',  value: 2},
        {label: 'Третье лицо', value: 3},
      ],
      applicantTypes: [
        {label: 'Отправитель', value: 1},
        {label: 'Получатель',  value: 2},
        {label: 'Плательщик',  value: 3},
      ],
      freightServices: [],
      stocks: [],
      stocksSuggest: [],
      search: '',
      doomSize: 0,
      doomWeight: 0,
      form: {
        deal: {
          agree: false,

          delivery_method_from: 2,
          delivery_method_to:   2,
          payer_type: 1,
          applicant_type: 1,
          payment_method_id: null,

          executor_company_id:  null,
          description:          null,
          from_stock_id:        null,
          to_stock_id:          null,
          from_address_id:      null,
          from_address:         {
            address: ''
          },
          to_address_id:        null,
          to_address:           {
            address: ''
          },
          applicant_id:         null,
          sender_id:            null,
          recipient_id:         null,
          payer_id:             null,
          status_id:            null,
          traking_number:       null,
          forwarding_date_from: null,
          forwarding_date_to:   null,
          responsible_id:       null,
          forwarder_from_id:    null,
          forwarder_to_id:      null,
          to_signature:         null,
          from_signature:       null,
          from_stock_signature:  null,
          to_signature_date:    null,
          from_signature_date:  null,
          from_stock_signature_date: null,


          sender: {
            search: '',
            name: '',
            type: 1,
            inn: '',
            kpp: '',
            passport_number: null,
            checking_account: '',
            correspondent_account: '',
            bic: '',
            bank: '',
            sort: 100,
            phone: '',
            email: "",
            contacts: [],
            can_edit: false,
          },

          recipient: {
            search: '',
            name: '',
            type: 1,
            inn: '',
            kpp: '',
            passport_number: null,
            checking_account: '',
            correspondent_account: '',
            bic: '',
            bank: '',
            sort: 100,
            phone: '',
            email: "",
            contacts: [],
            can_edit: false,
          },

          payer: {
            search: '',
            name: '',
            type: 1,
            inn: '',
            kpp: '',
            passport_number: null,
            checking_account: '',
            correspondent_account: '',
            bic: '',
            bank: '',
            sort: 100,
            phone: '',
            email: "",
            contacts: [],
            can_edit: false,
          },

          sender_contact: {
            id: '',
            name: '',
            phone: '',
            email: '',
          },

          recipient_contact: {
            id: '',
            name: '',
            phone: '',
            email: ''
          },

          payer_contact: {
            id: '',
            name: '',
            phone: '',
            email: ''
          }

        },
        freight: {
          id: '',
          description: '',
          sort: 100,
          size: '',
          size_units: 'м',
          status_id: '',
          traking_number: '',
          deal_id: '',
          departure_date: '',
          weight: '',
          seats_number: '',
          doom: false,
          factory_package: false,
          price: '',
          tracking_id: '',
          forwarder_id: null,
          forwarding_date: '',
          forward_type: null,
          from_signature: '',
          from_stock_signature:  null,
          to_signature: '',
          full_oversize: 0,
          oversize: 0,
          oversize_weight: null,
          oversize_size: null,
        },
        service: []
      },
      formDefault: {
        deal: {
          agree: false,

          delivery_method_from: 2,
          delivery_method_to:   2,
          payer_type: 1,
          applicant_type: 1,
          payment_method_id: null,


          executor_company_id:  null,
          description:          null,
          from_stock_id:        null,
          to_stock_id:          null,
          from_address_id:      null,
          from_address:         {
            address: ''
          },
          to_address_id:        null,
          to_address:           {
            address: ''
          },
          applicant_id:         null,
          sender_id:            null,
          recipient_id:         null,
          payer_id:             null,
          status_id:            null,
          traking_number:       null,
          forwarding_date_from: null,
          forwarding_date_to:   null,
          responsible_id:       null,
          forwarder_from_id:    null,
          forwarder_to_id:      null,
          to_signature:         null,
          from_signature:       null,
          from_stock_signature:  null,
          to_signature_date:    null,
          from_signature_date:  null,
          from_stock_signature_date: null,


          sender: {
            search: '',
            name: '',
            type: 1,
            inn: '',
            kpp: '',
            passport_number: null,
            checking_account: '',
            correspondent_account: '',
            bic: '',
            bank: '',
            sort: 100,
            phone: '',
            email: "",
            contacts: [],
            can_edit: false,
          },

          recipient: {
            search: '',
            name: '',
            type: 1,
            inn: '',
            kpp: '',
            passport_number: null,
            checking_account: '',
            correspondent_account: '',
            bic: '',
            bank: '',
            sort: 100,
            phone: '',
            email: "",
            contacts: [],
            can_edit: false,
          },

          payer: {
            search: '',
            name: '',
            type: 1,
            inn: '',
            kpp: '',
            passport_number: null,
            checking_account: '',
            correspondent_account: '',
            bic: '',
            bank: '',
            sort: 100,
            phone: '',
            email: "",
            contacts: [],
            can_edit: false,
          },

          sender_contact: {
            id: '',
            name: '',
            phone: '',
            email: '',
          },

          recipient_contact: {
            id: '',
            name: '',
            phone: '',
            email: ''
          },

          payer_contact: {
            id: '',
            name: '',
            phone: '',
            email: ''
          }

        },
        freight: {
          id: '',
          description: '',
          sort: 100,
          size: '',
          size_units: 'м',
          status_id: '',
          traking_number: '',
          deal_id: '',
          departure_date: '',
          weight: '',
          seats_number: '',
          doom: false,
          factory_package: false,
          price: '',
          tracking_id: '',
          forwarder_id: null,
          forwarding_date: '',
          forward_type: null,
          from_signature: null,
          from_stock_signature: null,
          to_signature: null,
          full_oversize: 0,
          oversize: 0,
          oversize_weight: null,
          oversize_size: null,
        },
        service: []
      }
    }
  },
  computed: {
    enableSignature() {
      return JSON.parse(this.$root.env.VUE_APP_ENABLE_SIGNATURE) === true;
    },
    hasSignature() {
      if (!this.enableSignature) return true; //Если подпись выключена, эмулируем наличие подписи
      if (!this.form.deal || !this.form.deal.from_signature) return false;
      return true;
    },
    currentPaymentMethod() {
      if (!this.form || !this.form.deal || !this.form.deal.payment_method_id) return;

      return this.paymentMethods.find(item => parseInt(item.id) === parseInt(this.form.deal.payment_method_id));
    },
    currentPaymentMethodIsCash() {
      if (this.currentPaymentMethod && this.currentPaymentMethod.type && ([3,4].indexOf(parseInt(this.currentPaymentMethod.type))+1)) return true;
      return false;
    },
    isCurrentPaymentMethodSelected(){
      return (this.currentPaymentMethod && this.currentPaymentMethod.id) ? true : false;
    },
    freightServicesPackage() {
      return this.freightServices.filter(item => item.section == 'package');
    }
  },
  watch: {
    'stocks'(stocks) {
      if (stocks && stocks.length) this.stocksSuggest = stocks.map(stock => {
        return {
          id: stock.id,
          name: stock.name + ((stock.city && stock.city.name) ? ', ' + stock.city.name : '')
        }
      });
    },
    'form.freight.doom'(doom) {
      if (doom) this.checkOversize();
      else {
        this.form.freight.oversize_size   = 0;
        this.form.freight.oversize_weight = 0;
        this.form.freight.full_oversize   = 0;
      }
    },
    'freight.size'(newSize) {
      setTimeout(() => {
        if (!!this.form.freight.doom && newSize == this.form.freight.size) this.checkOversize();
      }, 1000);
    },
    'freight.weight'(newWeight) {
      setTimeout(() => {
        if (!!this.form.freight.doom && newWeight == this.form.freight.weight) this.checkOversize();
      }, 1000);
    },
    'form.deal.payer_type'() {
      this.checkPayer();
    },
    'form.deal.applicant_type'() {
      this.checkApplicant();
    },
    'form.deal.payment_method_id'(value) {
      this.$nextTick(() => {
        if (value) this.focusSearchInput();
      });
    }
  },
  methods: {
    checkPayer() {
      if (parseInt(this.form.deal.payer_type) === 1) {
        this.form.deal.payer = Object.assign({}, this.form.deal.sender);
        this.form.deal.payer_id = this.form.deal.sender_id;
        this.form.deal.payer_contact = Object.assign({}, this.form.deal.payer_contact);
      } else if (parseInt(this.form.deal.payer_type) === 2) {
        this.form.deal.payer = Object.assign({}, this.form.deal.recipient);
        this.form.deal.payer_id = this.form.deal.recipient_id;
        this.form.deal.payer_contact = Object.assign({}, this.form.deal.recipient_contact);
      }
    },
    checkApplicant() {
      if (parseInt(this.form.deal.applicant_type) === 1) {
        this.form.deal.applicant = Object.assign({}, this.form.deal.sender);
        this.form.deal.applicant_id = this.form.deal.sender_id;
        this.form.deal.applicant_contact = Object.assign({}, this.form.deal.payer_contact);
      } else if (parseInt(this.form.deal.applicant_type) === 2) {
        this.form.deal.applicant = Object.assign({}, this.form.deal.recipient);
        this.form.deal.applicant_id = this.form.deal.recipient_id;
        this.form.deal.applicant_contact = Object.assign({}, this.form.deal.recipient_contact);
      } else if (parseInt(this.form.deal.applicant_type) === 3) {
        this.form.deal.applicant = Object.assign({}, this.form.deal.payer);
        this.form.deal.applicant_id = this.form.deal.payer_id;
        this.form.deal.applicant_contact = Object.assign({}, this.form.deal.payer_contact);
      }
    },
    scrollToError() {
      if (!this.errors || !this.errors.items || !this.errors.items.length || !this.errors.items[0]) return;
      let inputName  = this.errors.items[0]['field'],
          input      = document.querySelector('[name="' + inputName + '"]');

      if (input) input.scrollIntoView({block: "center", behavior: "smooth"});
    },
    onCapture(e) {
      this.submit();
    },
    onCaptureCancel() {
      //this.$emit('onCaptureCancel');
    },
    capture() {
      this.$validator.validateAll().then(valid => {
        if (!valid) return this.scrollToError();
        this.$refs.signature.$emit('capture');
      });
    },
    showError(message) {
      if (!message) return;
      this.error = message;
      setTimeout(() => this.error = '', 30000);
    },
  
    printDoc() {
      let url = this.$root.endpoint + '/print/receipt?client_print=1&deal_id=' + this.savedDeal.id;
      window.open(url, '_blank');
    },

    submit() {
      this.$validator.validateAll().then(valid => {
        if (!valid) return this.scrollToError();
          
        this.loading = true;
        this.form.service = Object.assign([], this.freightServices);
        if (this.form.freight) this.form.freight.doom = (this.form.freight.doom === true || parseInt(this.form.freight.doom) === 1) ? 1 : 0;
        Vue.http.post(this.$root.endpoint + '/ext/client', this.form).then(
          response => {
            if (response.body.response) {
              //заявка успешно оформлена
              this.complete = true;
              this.search = '';

              //печатаем экспедиторскую расписку
              this.savedDeal = Object.assign({}, response.body.response);
              this.printDoc();

              //говорим пользователю, что всё ОК
              if (!this.savedDeal || !this.savedDeal.first_freight || !this.savedDeal.first_freight.traking_number) this.showError('Заявка успешно сохранена.');
              else this.showError('Заявка успешно сохранена. Ваш номер отслеживания: ' + this.savedDeal.first_freight.traking_number);
            } else {
              if (response && response.body && response.body.errors && response.body.errors.messages) this.showError(response.body.errors.messages);
              else this.showError('Произошла ошибка при запросе, пожалуйста, обратитесь в техподдержку.');
            }

            this.toNewDealTimeOut();
            return response;
          }, response => {
            if (response && response.body && response.body.errors && response.body.errors.messages) this.showError(response.body.errors.messages);
            else this.showError('Произошла ошибка при запросе, пожалуйста, обратитесь в техподдержку.');
            this.toNewDealTimeOut();
            return response;
          }
        ).finally(() => this.loading = false);
      });
    },
    toNewDealTimeOut() {
      this.backTimeFn = setInterval(() => (this.backTime--), 1000);
      setTimeout(() => {
        this.toNewDeal();
        clearInterval(this.backTimeFn);
        this.backTime = 30;
      }, 30000);
    },
    toNewDeal() {
      this.$validator.pause()
      this.$validator.errors.clear()
      this.$validator.fields.items.forEach(field => field.reset())
      this.$validator.fields.items.forEach(field => this.errors.remove(field))

      this.$nextTick(() => {
        this.complete  = false;
        this.search    = '';
        this.savedDeal = {};
        this.error     = '';
        this.form     = Object.assign({}, this.formDefault);
        clearInterval(this.backTimeFn);
        this.backTime = 30;
        this.$nextTick(() => this.$validator.resume())
        window.location.reload();
      });
    },
    onFind(data) {
      //this.form.deal = Object.assign({}, data.deal);
      this.form = Object.assign({}, this.formDefault);
      this.form.deal = JSON.parse(JSON.stringify(data.deal));

      this.form.deal.payer_type = 1;
      this.form.deal.applicant_type = 1;
      
      this.form.deal.sender.contacts = [];
      this.form.deal.recipient.contacts = [];
      this.form.deal.payer.contacts = [];

      if (!this.form.deal.from_address) this.form.deal.from_address = {address: ''};
      if (!this.form.deal.to_address)   this.form.deal.to_address   = {address: ''};

      this.form.deal.sender.contacts.unshift({id: null, name: 'Создать контакт...'});
      this.form.deal.recipient.contacts.unshift({id: null, name: 'Создать контакт...'});
      this.form.deal.payer.contacts.unshift({id: null, name: 'Создать контакт...'});
      //this.$set(this.form.deal, 'sender_contact', {id: null});
      //this.$set(this.form.deal, 'recipient_contact', {id: null});
      //this.$set(this.form.deal, 'payer_contact', {id: null});
      this.form.freight = Object.assign({}, data.freight);
      this.form.service = Object.assign({}, data.service);

      this.form.freight.doom = (parseInt(this.form.freight.doom) === 1) ? true : false;
      this.form.freight.factory_package = (parseInt(this.form.freight.factory_package) === 1) ? true : false;
    },

    onClear() {
      this.complete = false;
      this.search = '';
      this.savedDeal = {};
      this.form = Object.assign({}, this.formDefault);
    },

    /**
     * Получение списка складов, доступных в CRM системе
     * @returns {*|PromiseLike<T>|Promise<T>}
     */
    getStocks() {
      return Vue.http.get(this.$root.endpoint + '/stock?count=50').then(
        response => {
          this.stocks = Object.assign([], response.body.response.data);
          return response;
        }, response => {
          this.showError('Произошла ошибка при получении складов доставки, пожалуйста, обратитесь в техподдержку.');
          return response;
        }
      )
    },

    /**
     * Получение списка компаний - исполнителей
     * @returns {*|PromiseLike<T>|Promise<T>}
    */
    getExecutors() {
      return Vue.http.get(this.$root.endpoint + '/company?count=10').then(
        response => {
          if (response && response.body && response.body.response && response.body.response.data) {
            let executors = response.body.response.data.map(item => {
              return {
                id: item.id,
                name: (parseInt(item.nds) ? ' С НДС' : ' Без НДС')
              }
            });
            this.vatValues = Object.assign([], executors);
          } else {
            this.showError('Произошла ошибка при получении типов оплаты, пожалуйста, обратитесь в техподдержку.');
            return null;
          }
          return response;
        }, response => {
          this.showError('Произошла ошибка при получении типов оплаты, пожалуйста, обратитесь в техподдержку.');
          return response;
        }
      )
    },

    /**
     * Выбор контакта отправителя из списка существующих у указанного контрагента
     * @param contact
     */
    onSelectSenderContact(contact) {
      if (contact) this.form.deal.sender_contact = Object.assign({}, contact);
      else this.form.deal.sender_contact = Object.assign({}, this.formDefault.deal.sender_contact);
    },

    /**
     * Выбор контакта получателя из списка существующих у указанного контрагента
     * @param contact
     */
    onSelectRecipientContact(contact) {
      if (contact) this.form.deal.recipient_contact = Object.assign({}, contact);
      else this.form.deal.recipient_contact = Object.assign({}, this.formDefault.deal.recipient_contact);
    },

    /**
     * Выбор контакта платльщика из списка существующих у указанного контрагента
     * @param contact
     */
    onSelectPayerContact(contact) {
      if (contact) this.form.deal.payer_contact = Object.assign({}, contact);
      else this.form.deal.payer_contact = Object.assign({}, this.formDefault.deal.payer_contact);
    },

    /**
     * Выбор отправителя из списка предложенных ДаДата/из БД
     * @param sender
     */
    suggestSender(sender) {
      if (sender) {
        if (sender.id) {
          this.form.deal.sender = Object.assign({}, sender);
          //if (sender.addresses && sender.addresses.length) {
            //let address = sender.addresses.find(item => !!item);
            //this.form.deal.from_address_id = address.id;
            //this.form.deal.from_address    = Object.assign({}, address);
          //}
          if (sender.contacts && sender.contacts.length) {
            let contact = sender.contacts.find(item => !!item);
            this.onSelectSenderContact(contact);
          }
        } else {
          this.form.deal.sender.name = sender.name;
          this.form.deal.sender.kpp  = sender.kpp;
          this.form.deal.sender.inn  = sender.inn;
          this.form.deal.sender.hid  = sender.hid;
          this.form.deal.sender.type = sender.type;
          this.form.deal.sender.address_legal = sender.address_legal;
        }
      } else {
        let type  = this.form.deal.sender.type,
            inn   = this.form.deal.sender.inn,
            pass  = this.form.deal.sender.passport_number;
        this.form.deal.sender = Object.assign({}, this.formDefault.deal.sender);
        this.form.deal.sender.type = type;
        this.form.deal.sender.inn  = inn;
        this.form.deal.sender.passport_number = pass;
        this.form.deal.sender.can_edit = true;

        //this.form.deal.from_address_id = null;
        //this.form.deal.from_address    = Object.assign({}, this.formDefault.deal.from_address);
        this.onSelectSenderContact(null);
      }
      this.$nextTick(() => {
        this.checkPayer();
        this.checkApplicant();
      });
    },

    /**
     * Выбор получателя из списка предложенных ДаДата/из БД
     * @param recipient
     */
    suggestRecipient(recipient) {
      if (recipient) {
        if (recipient.id) {
          this.form.deal.recipient = Object.assign({}, recipient);
          //if (recipient.addresses && recipient.addresses.length) {
            //let address = recipient.addresses.find(item => !!item);
            //this.form.deal.to_address_id = address.id;
            //this.form.deal.to_address    = Object.assign({}, address);
          //}
          if (recipient.contacts && recipient.contacts.length) {
            let contact = recipient.contacts.find(item => !!item);
            this.onSelectRecipientContact(contact);
          }
        } else {
          this.form.deal.recipient.name = recipient.name;
          this.form.deal.recipient.kpp  = recipient.kpp;
          this.form.deal.recipient.inn  = recipient.inn;
          this.form.deal.recipient.hid  = recipient.hid;
          this.form.deal.recipient.type = recipient.type;
          this.form.deal.recipient.address_legal = recipient.address_legal;
        }
      } else {
        let type  = this.form.deal.recipient.type,
            inn   = this.form.deal.recipient.inn,
            pass  = this.form.deal.recipient.passport_number;
        this.form.deal.recipient = Object.assign({}, this.formDefault.deal.recipient);
        this.form.deal.recipient.type = type;
        this.form.deal.recipient.inn  = inn;
        this.form.deal.recipient.passport_number = pass;
        this.form.deal.recipient.can_edit = true;

        //this.form.deal.to_address_id = null;
        //this.form.deal.to_address    = Object.assign({}, this.formDefault.deal.to_address);
        this.onSelectRecipientContact(null);
      }
      this.$nextTick(() => {
        this.checkPayer();
        this.checkApplicant();
      });
    },

    /**
     * Выбор плательщика из списка предложенных ДаДата/из БД
     * @param payer
     */
    suggestPayer(payer) {
      if (payer) {
        if (payer.id) {
          this.form.deal.payer = Object.assign({}, payer);
          if (payer.contacts && payer.contacts.length) {
            let contact = payer.contacts.find(item => !!item);
            this.onSelectPayerContact(contact);
          }
        } else {
          this.form.deal.payer.name = payer.name;
          this.form.deal.payer.kpp  = payer.kpp;
          this.form.deal.payer.inn  = payer.inn;
          this.form.deal.payer.hid  = payer.hid;
          this.form.deal.payer.type = payer.type;
          this.form.deal.payer_type = payer.type;
          this.form.deal.payer.address_legal = payer.address_legal;
        }
      } else {
        let type  = this.form.deal.payer.type,
            inn   = this.form.deal.payer.inn,
            pass  = this.form.deal.payer.passport_number;
        this.form.deal.payer = Object.assign({}, this.formDefault.deal.payer);
        this.form.deal.payer.type = type;
        this.form.deal.payer.inn  = inn;
        this.form.deal.payer.passport_number = pass;
        this.form.deal.payer.can_edit = true;
        
        this.onSelectPayerContact(null);
      }
      this.$nextTick(() => {
        this.checkApplicant();
      });
    },

    /**
     * Получает с сервера список активных дополительных услуг перевозки
     * @param section
     * @returns {*|PromiseLike<T>|Promise<T>}
     */
    getFreightServices(section) {
      let params = {
        orderBy: 'sort',
        orderType: 'asc',
        filter: (section) ?
          {model: [['section','=',section],'and',['active','=','1']], relation: []} :
          {model: [['active','=','1']], relation: []}
      };
      return Vue.http.get(this.$root.endpoint + '/deals/services', {params: params}).then(response => {
        let services = response.body.response.map(item => {
          if (item.type === 'checkbox') item.value = [];
          return item;
        });
        this.freightServices = Object.assign([], services);
        return services;
      }, response => {
        this.showError('Произошла ошибка при получении услуг перевозки, пожалуйста, обратитесь в техподдержку.');
        return response;
      });
    },
  
    /**
     * Устанавливает фокус на поле для поиска заявок
     */
    focusSearchInput() {
      if (this.$refs && this.$refs.searchinput && this.$refs.searchinput.$el) {
        let input = this.$refs.searchinput.$el.querySelector('input[type="text"]');
        if (input) input.focus();
      }
    },

    /**
     * Проверяет полный негабарит или не полный
     */
    checkOversize() {
      this.loading = true;
      return Vue.http.post(this.$root.endpoint + '/cost/freight/' + this.form.freight.id + '/checkoversize', this.form).then(r => {
          if (r && r.body && r.body.response) r = r.body.response;
          this.doomSize = parseFloat(r.doom_size);
          this.doomWeight = parseFloat(r.doom_weight);
          if (r && r.oversize_volume > 0 && r.oversize_weight > 0) {
            this.form.freight.full_oversize = 1;
            this.form.freight.oversize = 1;
            this.form.freight.oversize_weight = r.oversize_weight;
            this.form.freight.oversize_size   = r.oversize_volume;
          } else this.form.freight.full_oversize = 0;
      }).finally(() => this.loading = false);
    },

    getPaymentMethods() {
      return Vue.http.get(this.$root.endpoint + '/cost/payment_method', {
        params: {currentPage: 1, sortBy: 'id', sortOrder: 'asc', perPage: 100, filter: {model: [['active', '=', 1]]}}
      }).then(r => {
        if (r && r.body && r.body.response) this.paymentMethods = Object.assign([], r.body.response);
      });
    },

    inActivity() {
      let t;
      document.onmousemove = resetTimer;
      document.onkeypress  = resetTimer;
      document.onclick     = resetTimer;
      resetTimer();
  
      function logout() {
        location.reload();
      }
      function resetTimer() {
        clearTimeout(t);
        t = setTimeout(logout, 120000);
      }
    }
  },
  created: function() {
    this.$nextTick(() => {
      this.focusSearchInput();
      this.getStocks();
      this.getFreightServices();
      this.getExecutors();
      this.getPaymentMethods();
    })
  }
}
