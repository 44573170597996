import './index.css';
export default {
  name: 'progress-bar',
  props: {
    height: {
      type: String,
      default: '3px'
    },
  },
  components: {},
  data() {
    return {
    }
  },
  created() {
  },
  watch: {
  },
  computed: {},
  methods: {
  }
}
