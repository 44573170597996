import requestForm from './components/request-form/index.vue';
export default {
  components: {requestForm},
  name: 'app',
  data () {
    return {}
  },
  created: function () {
    //this.$root.helpers.listeners.listen('windowResize', e => this.checkMobileHeader());
  },
  computed: {

  },
  methods: {

  }
}
