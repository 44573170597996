import './index.css';
export default {
  name: 'input-button',
  props: {
    name: {
      type: String,
      default: 'input_button'
    },
    value: {
      type: String,
      default: 'Кнопка'
    }
  },
  components: {},
  data() {
    return {}
  },
  created() {
    this.$nextTick(() => {});
  },
  watch: {},
  computed: {},
  methods: {
    onClick(e) {
      e.preventDefault();
      this.$emit('click', e);
    },
  }
}
