export default {
  listeners: {
    windowResize: [],
    documentScroll: []
  },
  listen: function (name, callback) {
    if (typeof callback !== 'function') throw new Error('Callback is not a function');
    this.listeners[name].push(callback);
  },
  init() {
    window.onresize = (e) => {
      this.listeners.windowResize.map(callback => callback(e));
    };
    document.onscroll = (e) => {
      this.listeners.documentScroll.map(callback => callback(e, window.pageYOffset));
    };
  }
}
