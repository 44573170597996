String.prototype.translit = function () {
  return this.replace(/([а-яё])|([\s_-])|([^a-z\d])/gi,
    function (all, ch, space, words) {
      if (space || words) return space ? '-' : '';
      let code = ch.charCodeAt(0),
          index = code == 1025 || code == 1105 ? 0 :
          code > 1071 ? code - 1071 : code - 1039,
        t = ['yo', 'a', 'b', 'v', 'g', 'd', 'e', 'zh',
          'z', 'i', 'y', 'k', 'l', 'm', 'n', 'o', 'p',
          'r', 's', 't', 'u', 'f', 'h', 'c', 'ch', 'sh',
          'shch', '', 'y', '', 'e', 'yu', 'ya'
        ];
      return t[index];
    });
};


Array.prototype.removeByItemField = function (field, value) {
  if (field === undefined) throw new Error('Field is not defined');
  if (value === undefined) throw new Error('Field value is not defined');
  if (!this.length) throw new Error('Array is empty');
  let IndexOfRemoveItem = undefined;

  this.find((item, index) => {
    if (typeof item !== 'object') throw new Error('Array item type with index ' + item + ' is not a Object');
    if (item[field] === undefined) throw new Error('Field "' + field + '" is not defined in item with index ' + item);
    if (item[field] == value) {
      IndexOfRemoveItem = index;
      return true;
    } else return false;
  });

  if (IndexOfRemoveItem !== undefined) this.splice(IndexOfRemoveItem, 1);

  return this;
};
