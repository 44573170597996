import Vue                from 'vue';
import App                from './App.vue';
import VueResource        from 'vue-resource';
import VeeValidate        from 'vee-validate';
import ValidationMessages from 'vee-validate/dist/locale/ru';
import Moment             from 'moment';
import { store }          from './store';
import { helpers }        from "./helpers";
import flatpickr          from "flatpickr";
const VueInputMask = require('vue-inputmask').default;
import  "./helpers/mixins/index";
import './assets/css/norm.css';
import './assets/css/main.css';
const env = Object.assign({}, process.env);

Vue.use(flatpickr);
Vue.use(VueResource);
Vue.use(VueInputMask);
Vue.use(VeeValidate, {
  locale: 'ru',
  events: 'input|change|blur',
  dictionary: {
    ru: ValidationMessages
  }
});

Vue.prototype.moment = Moment;
Vue.http.headers.common['Authorization']    = localStorage.getItem('id_token');
Vue.http.headers.common['Accept']           = 'application/json, text/plain, */*';
Vue.http.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Vue.http.headers.common['Access-Control-Allow-Origin'] = '*';
Vue.http.options.emulateHTTP = true;

new Vue({
  store,
  components: {App},
  el: '#app',
  template: '<app/>',
  watch: {},
  helpers: helpers,
  data() {
    return {
      env: env,
      endpoint: env.VUE_APP_ENDPOINT
    }
  },
  methods: {},
  created: function () {
    this.$validator.extend('passport', {
      getMessage: field => 'Значение ' + field + ' должно быть корректным паспортом.',
      validate: (value) => {
        value = value.replace(/\D/g, "");
        let length = (value.length == 10);
        if (!length) return length;

        let year = value.substring(2, 4),
            number = value.substring(4),
            currentYear = new Date().getFullYear().toString().substring(2, 4);

        if (parseInt(number) < 101 || parseInt(number) > 999999) return false;
        if( (parseInt(year) >= 97 && parseInt(year) <= 99) || (parseInt(year) >= 0 && parseInt(year) <= parseInt(currentYear)) ) return true;
        else return false;
      }
    });
  },
  render: h => h(App)
});