import './index.css';
export default {
  name: 'input-text',
  props: {
    value: {
      type: [String, Number],
      default: () => {
        return ''
      }
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: ''
    },
    mask: {
      type: String,
      default: ''
    }
  },
  components: {},
  data() {
    return {
      inputValue: ''
    }
  },
  created() {
    this.$nextTick(() => {
      this.inputValue = this.$props.value;
    });
  },
  watch: {
    'inputValue'(newVal) {
      this.$emit('input', newVal);
      this.$emit('change', newVal);
    },
    '$props.value'(newVal) {
      if (this.inputValue != newVal) this.inputValue = newVal;
    }
  },
  computed: {},
  methods: {
    onChange(e) {
      this.inputValue = e.target.value;
    },
    onInput(e) {
      this.inputValue = e.target.value;
    }
  }
}
