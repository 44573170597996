import './index.css';
export default {
  name: 'input-radio',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    values: {
      type: Array,
      default: () => [{
        label: 'Радио кнопка',
        value: 1
      }]
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },

    vAlias: {
      type: String,
      default: 'label'
    },
    vValue: {
      type: String,
      default: 'value'
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      inputValue: null
    }
  },
  created() {
    this.$nextTick(() => {
      this.inputValue = this.$props.value;
    });
  },
  watch: {
    'inputValue'(newVal) {
      this.$emit('input', newVal);
      this.$emit('change', newVal);
    },
    '$props.value'(newVal) {
      if (this.inputValue != newVal)
        this.inputValue = newVal;
    }
  },
  computed: {
    id() {
      return this._uid;
    }
  },
  methods: {}
}
