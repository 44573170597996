import Vue from 'vue';
import inputButton from './../input-button/index.vue';
import './index.css';
export default {
  name: 'input-deal',
  props: {
    value: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    services: {
      type: Array,
      default: []
    }
  },
  components: {inputButton},
  data() {
    return {
      inputValue: '',
      focus: false,
      loading: false,
      error: '',
      result: {
        deal: {},
        freight: {},
        service: {}
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.inputValue = this.$props.value;
    });
  },
  watch: {
    'inputValue'(newVal) {
      this.$emit('input', newVal);
      this.$emit('change', newVal);

      let checker = setInterval(() => {
        if (newVal == this.inputValue) {
          if (newVal.length >= 10 && this.focus && !this.loading) {
            if ((this.inputValue.indexOf('/')+1)) this.inputValue = this.inputValue.split('/')[0];
            this.find();
          }
          clearInterval(checker);
        }
      }, 500);
    },
    '$props.value'(newVal) {
      if (this.inputValue != newVal) this.inputValue = newVal;
    }
  },
  computed: {},
  methods: {
    find() {
      this.loading = true;
      return Vue.http.get(this.$root.endpoint + '/deals/by_freight_code/' + this.inputValue).then(
        response => {
          let deal = response.body.response;
          deal = this.getDefaultCustomers(deal);
          this.result.deal = Object.assign({}, deal);
          if (this.result.deal && this.result.deal.id) this.prepare();
        }
      ).finally(() => this.loading = false);
      
    },
    getDefaultCustomers(deal) {
      let customer = {
        search: '',
        name: '',
        type: 1,
        inn: '',
        kpp: '',
        passport_number: null,
        checking_account: '',
        correspondent_account: '',
        bic: '',
        bank: '',
        sort: 100,
        phone: '',
        email: "",
        contacts: []
      }
      
      if (!deal.sender) deal.sender = Object.assign({}, customer);
      if (!deal.payer)  deal.payer = Object.assign({}, customer);
      if (!deal.recipient) deal.recipient = Object.assign({}, customer);
      if (!deal.applicant) deal.applicant = Object.assign({}, customer);

      return deal;
    },
    prepare() {
      //определяем доп параметры для формы
      this.result.deal.agree = false;
      this.result.deal.delivery_method_from = (this.result.deal.from_address_id)   ? 2 : 1;
      this.result.deal.delivery_method_to   = (this.result.deal.to_address_id)     ? 2 : 1;
      this.result.deal.payment_method_id    = (this.result.deal.payment_method_id) ? parseInt(this.result.deal.payment_method_id) : 2;

      if (parseInt(this.result.deal.payer_id) === parseInt(this.result.deal.sender_id)) {
        this.result.deal.payer_type = 1;
      } else if (parseInt(this.result.deal.payer_id) === parseInt(this.result.deal.recipient_id)) {
        this.result.deal.payer_type = 2;
      } else {
        this.result.deal.payer_type = 3;
      }

      if (parseInt(this.result.deal.applicant_id) === parseInt(this.result.deal.sender_id)) {
        this.result.deal.applicant_type = 1;
      } else if (parseInt(this.result.deal.applicant_id) === parseInt(this.result.deal.recipient_id)) {
        this.result.deal.applicant_type = 2;
      } else {
        this.result.deal.applicant_type = 3;
      }

      //переписываем контакты, так, чтобы форма их понимала
      if (this.result.deal.contacts) {
        let AllContacts = {};
        if (this.result.deal.sender && this.result.deal.sender.contacts && this.result.deal.sender.contacts.length)
          this.result.deal.sender.contacts.map(item => {AllContacts[item.id] = item;});

        if (this.result.deal.recipient && this.result.deal.recipient.contacts && this.result.deal.sender.contacts.length)
          this.result.deal.recipient.contacts.map(item => {AllContacts[item.id] = item;});

        if (this.result.deal.payer && this.result.deal.payer.contacts && this.result.deal.sender.contacts.length)
          this.result.deal.payer.contacts.map(item => {AllContacts[item.id] = item;});

        this.result.deal.contacts.map(contact => {
          this.result.deal[(contact.type + '_contact')] = {
            id: contact.contact_id,
            name:  (AllContacts[contact.contact_id]) ? AllContacts[contact.contact_id]['name']  : '',
            phone: (AllContacts[contact.contact_id]) ? AllContacts[contact.contact_id]['phone'] : '',
            email: (AllContacts[contact.contact_id]) ? AllContacts[contact.contact_id]['email'] : '',
          };
        });

        if (!this.result.deal.sender_contact)    this.result.deal.sender_contact    = {id: '', name: '', phone: '', email: ''};
        if (!this.result.deal.recipient_contact) this.result.deal.recipient_contact = {id: '', name: '', phone: '', email: ''};
        if (!this.result.deal.payer_contact)     this.result.deal.payer_contact     = {id: '', name: '', phone: '', email: ''};
      }

      //получаем груз, а вместе с ним и список доп услуг и переписываем их
      this.result.freight = Object.assign({}, this.result.deal.first_freight);
      this.getFreight(this.result.freight.id).then(freight => {
        let services = Object.assign([], this.$props.services);
        if (freight && freight.services) {
          freight.services.map(serviceValue => {
            services.map(item => {
              if (parseInt(item.id) === parseInt(serviceValue.service.id)) {
                if (item.type === 'checkbox') {
                  item.value = [];
                  item.value.push(serviceValue.id);
                } else item.value = serviceValue.id;
              }
            });
          });
        }
        this.result.service = Object.assign([], services);
        if (parseInt(this.result.freight.doom) == 0) this.result.freight.doom = null;
        if (parseInt(this.result.freight.oversize) == 0) this.result.freight.oversize = null;
        this.$emit('find', this.result);
      });

    },
    getFreight(id) {
      this.loading = true;
      return Vue.http.get(this.$root.endpoint + '/deals/freight/byid/' + id).then(
        response => response.body.response,
        () => false
      ).finally(() => this.loading = false);
    },
    clear() {
      this.$emit('clear');
    },
    onFocus() {
      this.focus = true;
    },
    onBlur() {
      setTimeout(() => {
        this.focus = false;
      }, 100);
    },
    onChange(e) {
      this.inputValue = e.target.value;
    },
    onInput(e) {
      this.inputValue = e.target.value;
    }
  }
}
