import WacomGSS_SignatureSDK from './WacomSigCaptXSDK/WacomGSS_SignatureSDK';
class WacomSignatureManager {
  constructor(size, debug) {
    this.wgssSignatureSDK = null;
    this.sigObj = null;
    this.sigCtl = null;
    this.dynCapt = null;
    this.size = size;
    this.debug = debug || false;

    this.printMsg('WacomSignature is run', false, true);
  };

  printMsg(txt, isError, debug) {
    isError = !!isError;
    debug = !!debug;
    if (!isError) {
      if (debug && !this.debug) return;
      console.log('[W_SIG_MANAGER]: ' + txt);
    } else {
      if (this.errorCallback) this.errorCallback('Ошибка подписи: ' + txt);
      //throw new Error('[W_SIG_MANAGER]: ' + txt);
    }
  };

  OnLoad(callback, onCancelCapture) {
    this.restartSession(callback, onCancelCapture);
    if (onCancelCapture) this.onCancelCapture = onCancelCapture;
  };

  restartSession(callback, onError) {
    this.wgssSignatureSDK = null;
    this.sigObj = null;
    this.sigCtl = null;
    this.dynCapt = null;

    let timeout = setTimeout(this.timedDetect, 1500);
    setTimeout(() => {
      // pass the starting service port  number as configured in the registry
      this.wgssSignatureSDK = new WacomGSS_SignatureSDK(this.onDetectRunning, 8000);
    },0);
    this.timedDetect = () => {
      if (this.wgssSignatureSDK && this.wgssSignatureSDK.running) {
        this.printMsg("Дрейвер подписи не запущен.", false, true);
        this.start();
      } else {
        if (onError) onError('Планшет недоступен. Проверьте подключение и драйвер.');
      }
    };

    this.onDetectRunning = () => {
      if (this.wgssSignatureSDK && this.wgssSignatureSDK.running) {
        this.printMsg("Драйвер подписи не запущен.", false, true);
        clearTimeout(timeout);
        this.start();
      } else {
        if (onError) onError('Планшет недоступен. Проверьте подключение и драйвер.');
      }
    };

    this.start = () => {
      if (this.wgssSignatureSDK && this.wgssSignatureSDK.running)
        this.sigCtl = new this.wgssSignatureSDK.SigCtl(this.onSigCtlConstructor);
    };

    this.onSigCtlConstructor = (sigCtlV, status) => {
      if (this.sigCtl && this.wgssSignatureSDK && this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.OK == status) {
        let licence = 'AgAkAEy2cKydAQVXYWNvbQ1TaWduYXR1cmUgU0RLAgKBAgJkAACIAwEDZQA';
        this.sigCtl.PutLicence(licence, this.onSigCtlPutLicence);
        //this.dynCapt = new this.wgssSignatureSDK.DynamicCapture(this.onDynCaptConstructor);
      } else this.printMsg("Ошибка создания ключа лицензии: " + status, true);
    };

    this.onSigCtlPutLicence = (sigCtlV, status) => {
      if (this.wgssSignatureSDK && this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.OK == status) {
        this.dynCapt = new this.wgssSignatureSDK.DynamicCapture(this.onDynCaptConstructor);
      } else this.printMsg("Ошибка создания инстанса ключа подписи: " + status, true);
    };

    this.onDynCaptConstructor = (dynCaptV, status) => {
      if (this.sigCtl && this.wgssSignatureSDK && this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.OK == status)
        this.sigCtl.GetSignature(this.onGetSignature);
      else
        this.printMsg("Ошибка создания инстанса подписи: " + status, true);
    };

    this.onGetSignature = (sigCtlV, sigObjV, status) => {
      if (this.sigCtl && this.wgssSignatureSDK && this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.OK == status) {
        this.sigObj = sigObjV;
        this.sigCtl.GetProperty("Component_FileVersion", this.onSigCtlGetProperty);
      } else this.printMsg("Ошибка получения подписи из драйвера: " + status, true);
    };

    this.onSigCtlGetProperty = (sigCtlV, property, status) => {
      if (this.wgssSignatureSDK && this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.OK == status) {
        this.printMsg("DLL: flSigCOM.dll  v" + property.text, false, true);
        this.dynCapt.GetProperty("Component_FileVersion", this.onDynCaptGetProperty);
      } else this.printMsg(status, true);
    };

    this.onDynCaptGetProperty = (dynCaptV, property, status) => {
      if (this.wgssSignatureSDK && this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.OK == status){
        this.printMsg("DLL: flSigCapt.dll v" + property.text, false, true);
        this.printMsg("Application ready.", false, true);
        this.printMsg("Press 'Start' to capture a signature.", false, true);
        if('function' === typeof callback) callback();
      } else this.printMsg(status, true);
    }

  };

  Capture(onCapture, onError) {
    this.errorCallback = onError;
    if (!this.wgssSignatureSDK || !this.wgssSignatureSDK.running || null === this.dynCapt) {
      this.printMsg("Session error. Restarting the session.", false, true);
      this.restartSession(window.Capture, onError);
      return;
    }
    setTimeout(() => {
      this.dynCapt.Capture(this.sigCtl, "who", "why", null, null, this.onDynCaptCapture);
    }, 0);

    this.onDynCaptCapture = (dynCaptV, SigObjV, status) => {
      if (this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.INVALID_SESSION === status) {
        this.printMsg("Error: invalid session. Restarting the session.", false, true);
        this.restartSession(window.Capture, onError);
      } else {
        if (this.wgssSignatureSDK.DynamicCaptureResult.DynCaptOK !== status)
          this.printMsg("Capture returned: " + status, false, true);

        switch(status) {
          case this.wgssSignatureSDK.DynamicCaptureResult.DynCaptOK:
            this.sigObj = SigObjV;
            this.printMsg("Signature captured successfully", false, true);
            let flags = this.wgssSignatureSDK.RBFlags.RenderOutputPicture |
              this.wgssSignatureSDK.RBFlags.RenderColor24BPP;
            setTimeout(() => {
              this.sigObj.RenderBitmap("png", this.size.width, this.size.height, 0.7, 0x00000000, 0x00FFFFFF, flags, 0, 0, this.onRenderBitmap);
            }, 0);
            break;
          case this.wgssSignatureSDK.DynamicCaptureResult.DynCaptCancel:
            this.printMsg("Signature capture cancelled", false, true);
            if (onError) onError("Подпись отменена");
            if (this.onCancelCapture) this.onCancelCapture();
            break;
          case this.wgssSignatureSDK.DynamicCaptureResult.DynCaptPadError:
            this.printMsg("No capture service available", false, true);
            if (onError) onError("Планшет недоступен (проверьте драйвер устройства)");
            break;
          case this.wgssSignatureSDK.DynamicCaptureResult.DynCaptError:
            this.printMsg("Tablet Error", false, true);
            if (onError) onError("Планшет недоступен (проверьте соединение с устройством)");
            break;
          case this.wgssSignatureSDK.DynamicCaptureResult.DynCaptIntegrityKeyInvalid:
            this.printMsg("The integrity key parameter is invalid (obsolete)", false, true);
            if (onError) onError("Ключ целостности подписи устарел (попробуйте подписать ещё раз)");
            break;
          case this.wgssSignatureSDK.DynamicCaptureResult.DynCaptNotLicensed:
            this.printMsg("No valid Signature Capture licence found", false, true);
            if (onError) onError("Не обнаружен ключ лицензии");
            break;
          case this.wgssSignatureSDK.DynamicCaptureResult.DynCaptAbort:
            this.printMsg("Error - unable to parse document contents", false, true);
            if (onError) onError("Чтение документа подписи невозможно");
            break;
          default:
            this.printMsg("Capture Error " + status, false, true);
            if (onError) onError("Неизвестная ошибка" + status);
            break;
        }
      }
    };

    this.onRenderBitmap = (sigObjV, bmpObj, status) => {
      if (this.wgssSignatureSDK && this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.OK == status) {
        onCapture(bmpObj.image.src);
      } else {
        if (onError) onError("Ошибка рендера изображения подписи: " + status);
      }
    }
  };

  DisplaySignatureDetails() {
    if (!this.wgssSignatureSDK.running || null === this.sigObj) {
      this.printMsg("Session error. Restarting the session.", false, true);
      this.restartSession(window.DisplaySignatureDetails);
      return;
    }
    setTimeout(() => {
      this.sigObj.GetIsCaptured(this.onGetIsCaptured);
    }, 0);

    this.onGetIsCaptured = (sigObj, isCaptured, status) => {
      if (this.wgssSignatureSDK && this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.OK == status) {
        if (!isCaptured) {
          this.printMsg("No signature has been captured yet.", false, true);
          return;
        }
        this.sigObj.GetWho(this.onGetWho);
      } else {
        this.printMsg("Signature GetWho error: " + status, false, true);
        if (this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.INVALID_SESSION === status) {
          this.printMsg("Session error. Restarting the session.", false, true);
          this.restartSession(window.DisplaySignatureDetails);
        }
      }
    };

    this.onGetWho = (sigObjV, who, status) => {
      if (this.wgssSignatureSDK && this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.OK == status) {
        this.printMsg("  Name:   " + who);
        let tz = this.wgssSignatureSDK.TimeZone.TimeLocal;
        this.sigObj.GetWhen(tz, this.onGetWhen);
      } else this.printMsg("Ошибка получения подписываюещего: " + status, true);
    };

    this.onGetWhen = (sigObjV, when, status) => {
      if (this.wgssSignatureSDK && this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.OK == status) {
        this.printMsg("  Date:   " + when.toString() );
        this.sigObj.GetWhy(this.onGetWhy);
      } else this.printMsg("Ошибка получения времени подписи: " + status, true);
    };

    this.onGetWhy = (sigObjV, why, status) => {
      if (this.wgssSignatureSDK && this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.OK == status)
        this.printMsg("  Reason: " + why, false, true);
      else
        this.printMsg("Signature GetWhy error: " + status, false, true);
    }
  };

  AboutBox() {
    if (!this.wgssSignatureSDK.running || null === this.sigCtl) {
      this.printMsg("Session error. Restarting the session.", false, true);
      this.restartSession(window.AboutBox);
      return;
    }
    setTimeout(() => {
      this.sigCtl.AboutBox(this.onAboutBox);
    },0);

    this.onAboutBox = (sigCtlV, status) => {
      if (this.wgssSignatureSDK && this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.OK !== status) {
        this.printMsg("AboutBox error: " + status, false, true);
        if (this.wgssSignatureSDK.ResponseStatus && this.wgssSignatureSDK.ResponseStatus.INVALID_SESSION === status) {
          this.printMsg("Session error. Restarting the session.", false, true);
          this.restartSession(window.AboutBox);
        }
      }
    }
  }
}

export { WacomSignatureManager as default};