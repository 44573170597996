//скрипт, который автоматически собирает все роуты и модули vuex
let helpers = [];

//находим все файлы с именем routes.js в текущей папке
let files = require.context('.', true, /index\.js$/);
files.keys().forEach(key => {
  let helperName = key.match(/\.\/(.+?)\//);
  if (helperName) {
    helperName = helperName[1];
    helpers[helperName] = files(key).default;
  }
});
export { helpers }
